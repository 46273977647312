import React,{useState} from 'react'
import {Link,useNavigate} from 'react-router-dom'
import './Login.css'
import showPwdImg from '../../assets/show-password.svg';
import hidePwdImg from '../../assets/hide-password.svg';

const Login = () => {


    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const navigate=useNavigate();

    let initTodo;
    initTodo = JSON.parse(localStorage.getItem("LoginD"));


const handlesubmit=(e)=>{
e.preventDefault();
if(initTodo===null){
  alert('information not available');  
}
else if(initTodo.find((user)=>user.username===username) && initTodo.find((user)=>user.password===password)){
  console.log("right info");
  navigate("/home");
}else{
  alert('wrong detailed');  
} 

}

  return (
    <div className='reg'>
    <div className='Login'>
    <form className="container my-3" onSubmit={handlesubmit} >
      <h3>Login Form</h3>
      <div className="container">
        <label htmlFor="uname" className="form-label">
          User Name
        </label>
        <input
        style={{padding:"15px",
            margin: "10px 0px",
            borderRadius: "5px",
            border: "1px solid gray"}}
        
          type="text"
          className="form-control"
          id="username" value={username} onChange={(e)=>setUsername(e.target.value)}
          aria-describedby="emailHelp" autoComplete='username'
          required
        />
        
      </div>
      <div className="container" style={{position:'relative'}}>
        <label htmlFor="pwd" className="form-label">
          Password
        </label>
        <input
        style={{padding:"15px",
        margin: "10px 0px",
        borderRadius: "5px",
         border: "1px solid gray"}}
          type={isRevealPwd ? "text" : "password"}
          className="form-control" autoComplete='new-password' value={password}
          id="password" onChange={(e)=>setPassword(e.target.value)}
          required
        />
        <img alt=""
        style={{cursor:'pointer',position:'absolute',width:'18px',right:'8px',top:'40px'}}
          title={isRevealPwd ? "Hide password" : "Show password"}
          src={isRevealPwd ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwd(prevState => !prevState)}
        />
      </div>
      
      <button type="submit"
 
 className="btn btn-sm btn-success" style={{backgroundColor:'rebeccapurple'}}>
        Login
      </button>
      
      <Link className="link" to="/registration" style={{  marginBottom: "20px",
    textAlign: "center"}}>New User Registration</Link>
      
    </form>
  </div>
  </div>
  )
}

export default Login
