import React from 'react'
import './Footer.css'
import Github from '../../assets/github.png'
import Instagram from '../../assets/instagram.png'
import Facebook from '../../assets/facebook.png'
import Logo from '../../assets/logo.png'
import { Link } from "react-scroll";
const Footer = () => {
   return (
    <div className='Footer-container' id='footer'>
      <hr></hr>
      <div className='footer'>
        <div className='social-links'>
        <img src={Github} alt=''/>
        <a href="https://www.instagram.com/jaydeepyadav__jd__178">
        <img src={Instagram} alt=''  width="40" height="40"/>
        </a>
        <a className='facebook' href='https://www.facebook.com/profile.php?id=100008671355917'>
        <img  src={Facebook} alt='' width="40" height="40"/>
        </a>
        
      </div>      
      <div className='logo-f'>
     
        <img src={Logo} alt=''/>
      
      </div>
      <div>
      <Link to='home' style={{backgroundColor: "white",
    padding: "15px",
    borderRadius: "5px",
    cursor: "pointer"  ,   textAlign: "center",
    fontWeight: "bold",
    marginBottom: "11px",
    marginTop: "2px"}} spy={true} smooth={true}>Scroll  Up</Link>
      </div>
    
      </div>
      <div className='blur blur-f-1'></div>
      <div className='blur blur-f-2'></div>
    <div>developed by jd</div>
    </div>
    
  )
}

export default Footer
