import React from "react";
import Login from "./components/Login/Login";
import Registration from "./components/Registration/Registration";
import App from "./App";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function Home() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login></Login>}/>
          <Route exact path="/registration" element={<Registration></Registration>}/>
          <Route exact path="/home" element={<App></App>} />   
          
        </Routes>
      </Router>
    </>
  );
}

export default Home;
