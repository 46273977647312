import React from "react";
import { useState, useEffect } from "react";
import "./Registration.css";
import { Link, useNavigate } from "react-router-dom";
import showPwdImg from "../../assets/show-password.svg";
import hidePwdImg from "../../assets/hide-password.svg";

const Registration = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();
  let initTodo;
  if (localStorage.getItem("LoginD") === null) {
    initTodo = [];
  } else {
    initTodo = JSON.parse(localStorage.getItem("LoginD"));
  }
  const [rdata, setRdata] = useState(initTodo);
  const handlesubmit = (e) => {
    e.preventDefault();
    addD();
    alert("Registration Successful");
    navigate("/");
    // setUsername('');
    // setEmail('');
    // setBirthday('');
    // setPassword('');
    // setConfirmPassword('');
  };
  const addD = () => {
    let sno;
    if (rdata.length === 0) {
      sno = 0;
    } else {
      sno = rdata[rdata.length - 1].sno + 1;
    }
    const myTodo = {
      sno: sno,
      username: username,
      email: email,
      birthday: birthday,
      password: password,
    };
    console.log(myTodo);
    if(myTodo!=null){     
      localStorage.setItem("LoginD", JSON.stringify([...rdata, myTodo]));
      console.log("I am adding this todo", rdata);
    }    
  };

  return (
    <div className="reg">
      <div className="Ap">
        <form className="" onSubmit={handlesubmit}>
          <h1>Registration</h1>
          <label>Username</label>
          <input
            style={{
              padding: "15px",
              margin: "10px 0px",
              borderRadius: "5px",
              border: "1px solid gray",
            }}
            value={username}
            type="text"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            title="Must contain atleast one upper letter"
            required
          ></input>
          <label>Email</label>
          <input
            style={{
              padding: "15px",
              margin: "10px 0px",
              borderRadius: "5px",
              border: "1px solid gray",
            }}
            value={email}
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="username"
            required
          ></input>
          
          <div className="container" style={{ position: "relative" }}>
            <label>Password</label>
            <input
              style={{
                padding: "15px",
                margin: "10px 0px",
                borderRadius: "5px",
                border: "1px solid gray",
              }}
              value={password}
              type={isRevealPwd ? "text" : "password"}
              placeholder="Password"
              title="Must contain at least one number and one uppercase and lowercase letter, and atleast 8 or more characters"
              onChange={(e) => setPassword(e.target.value)}
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              required
              autoComplete="new-password"
            ></input>
            <img
              alt=""
              style={{
                cursor: "pointer",
                position: "absolute",
                width: "18px",
                right: "8px",
                top: "40px",
              }}
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            />
          </div>
          <div className="container" style={{ position: "relative" }}>
            <label>confirmPassword</label>
            <input
              style={{
                padding: "15px",
                margin: "10px 0px",
                borderRadius: "5px",
                border: "1px solid gray",
              }}
              value={confirmPassword}
              type={isRevealPwd ? "text" : "password"}
              placeholder="confirmPassword"
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              pattern={password}
              required={true}
            ></input>
            <img
              alt=""
              style={{
                cursor: "pointer",
                position: "absolute",
                width: "18px",
                right: "8px",
                top: "40px",
              }}
              title={isRevealPwd ? "Hide password" : "Show password"}
              src={isRevealPwd ? hidePwdImg : showPwdImg}
              onClick={() => setIsRevealPwd((prevState) => !prevState)}
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Registration;
